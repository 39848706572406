<script setup lang="ts">
import SvgIcon from "~/components/Common/SvgIcon.vue";
import Loader from "../Loader.vue";

const props = defineProps({
  name: {
    type: String,
  },
  default: {
    type: Boolean,
    default: true,
  },
  active: {
    type: Boolean,
    default: false,
  },
  loader: {
    type: Boolean,
    default: false,
  },

  width: {
    type: Number,
    default: 24,
  },

  height: {
    type: Number,
    default: 24,
  },

  radius: {
    type: Number,
    default: 12,
  },
});

</script>

<template>
  <button :disabled="loader" :class="[props.default ? 'svg_button__button_blue' : 'svg_button__button_red']" :style="{ borderRadius: radius + 'px' }" v-bind="$attrs" v-if="name">
    <SvgIcon v-if="!loader"    :style="{ width: width + 'px', height: height + 'px' }"
    :name="name"></SvgIcon>

    <Loader v-else class="h-[16px] w-[16px]" :width="20" :height="20"></Loader>

  </button>
</template>

<style scoped>
.svg_button__button_blue {
  @apply flex items-center justify-center rounded-[12px];
  box-sizing: border-box;
  border: 1px solid var(--primary-blue-color);
  color: var(--primary-blue-color);
  height: 40px;
  width: 40px;

  &:hover {
    background-color: var(--grey-light);
    border-color: var(--blue-dark);
    color: var(--blue-dark);
  }

  &:active  {
    background-color: var(--grey-lightest);
  }

  &:disabled {
    border: 1px solid var(--grey-medium-light);
    color: var(--grey-medium-light);
  }
}

.svg_button__button_red {
  @apply flex items-center justify-center rounded-[12px];
  box-sizing: border-box;
  border: 1px solid var(--red-dark);
  color: var(--red-dark);
  height: 40px;
  width: 40px;

  &:hover {
    background-color: var(--grey-light);
    border-color: var(--red-main);
    color: var(--red-main);
  }

  &:active  {
    background-color: var(--grey-lightest);
  }

  &:disabled {
    border: 1px solid var(--grey-medium-light);
    color: var(--grey-medium-light);
  }
}

.svg_button__img {
  width: 24px;
  height: 24px;
}
</style>
